import React from 'react'
import Fade from 'react-reveal/Fade'
import Modal from './Modal'
import TeamModal from './TeamModal'

const Body = class extends React.Component {
  toggleModal = (e) => {
    document.body.classList.add('modal-open')
    e.currentTarget.closest('.cMain-item').classList.add('active')
  }
  render() {
    const { data } = this.props
    return (
      <section className="cMain-inner inner">
          <div className="cMain-content">
            {data && Object.keys(data).map((key, idx) => {
              return (
                key === 'main' ? '' : (
                  <div className="cMain-item" key={idx} name={key}>
                    <Fade>
                      <h2 className="cMain-itemHeading">
                        <button className="cMain-itemBtn" type="button" onClick={(e) => this.toggleModal(e)}><span>{data[key].heading}</span></button>
                      </h2>
                    </Fade>
                    {key === 'team' ? (
                      <TeamModal data={data[key]} />
                    ) : (
                      <Modal data={data[key]} />
                    )}
                  </div>
                )
              )
            })}
          </div>
      </section>
    )
  }
}

export default Body
