import React from 'react'
import Fade from 'react-reveal/Fade'
import ReactMarkdown from 'react-markdown'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const Modal = class extends React.Component {
  toggleModal = (e) => {
    document.body.classList.remove('modal-open')
    e.currentTarget.closest('.cMain-item').classList.remove('active')
  }
  render() {
    const data = this.props.data
    return (
      <section className="cModal">
        <div className="cModal-inner inner">
          <button className="cModal-close" onClick={(e) => this.toggleModal(e)}>
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
          </button>
          <div className="cModal-content">
            <h2 className="cModal-heading">{data.heading}</h2>
            <div className="cModal-body">
              <ReactMarkdown className="cModal-text" source={data.text} />
            </div>
            {data.blocks &&
              <div className="cModal-list">
                {data.blocks && data.blocks.map((block, idx) => (
                  <div className="cModal-item" key={idx}>
                    <div className="cModal-image">
                      <Fade>
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: block.image,
                            alt: block.name,
                          }}
                        />
                      </Fade>
                    </div>
                    <p className="cModal-date">{ block.date }</p>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </section>
    )
  }
}

export default Modal
