import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Main from '../components/Main'

export const IndexPageTemplate = ({ data }) => (
  <div className="cMain">
    <Main data={data} />
  </div>
)

IndexPageTemplate.propTypes = {
  data: PropTypes.shape({
    main: PropTypes.shape({
      backgroundImages: PropTypes.array
    }),
    commitment: PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string
    }),
    different: PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string
    }),
    about: PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string
    }),
    team: PropTypes.shape({
      heading: PropTypes.string,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          name: PropTypes.string,
          title: PropTypes.string,
          company: PropTypes.string,
          description: PropTypes.string
        })
      )
    }),
    investments: PropTypes.shape({
      heading: PropTypes.string,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          name: PropTypes.string,
          date: PropTypes.string
        })
      )
    }),
    strategy: PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string
    }),
    // contact: PropTypes.shape({
    //   heading: PropTypes.string,
    //   text: PropTypes.string
    // })
  })
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout data={frontmatter.main}>
      <IndexPageTemplate data={frontmatter} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        main {
          backgroundImages {
            image {
              childImageSharp {
                fluid(maxWidth: 2500, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        commitment {
          heading
          text
        }
        different {
          heading
          text
        }
        about {
          heading
          text
        }
        team {
          heading
          blocks {
            image {
              childImageSharp {
                fluid(maxWidth: 700, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            title
            company
            description
          }
        }
        investments {
          heading
          blocks {
            image {
              childImageSharp {
                fluid(maxWidth: 700, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            date
          }
        }
        strategy {
          heading
          text
        }
        # contact {
        #   heading
        #   text
        # }
      }
    }
  }
`
